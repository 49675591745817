<style>
table thead th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
}
table thead th > i {
  position: absolute;
  font-size: 13px !important;
  transform: translateY(1px) translateX(2px);
}
table tbody tr {
  cursor: pointer;
}

td.table-client_symbol {
  font-size: 12px;
}
</style>

<template>
  <table>
    <thead>
      <tr>
        <th
          v-for="(header, idx) in headers"
          v-bind:key="idx"
          @click="sort(idx)"
        >
          {{ header }}
          <i
            v-if="idx == sortIdx"
            :class="sortRev ? 'fa-sort-desc' : 'fa-sort-asc'"
            class="fa"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in displayItems"
        :key="item.id"
        @click="$emit('tr:clicked', item)"
      >
        <td
          v-for="(field, idx) in fields"
          v-bind:key="idx"
          :data-collabel="headers[idx]"
          :class="['table-' + field]"
        >
          {{ item[field] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    items: Array,
    search: String,
    fields: Array,
    headers: Array,
    page: Number,
    itemsPerPage: Number,
    pages: Number,
    delay: {
      type: Number,
      default: 200,
      required: false,
    },
    defaultSort: Object,
    fieldTypes: {
      type: Object,
      default: () => ({}),
    },
    additionalSearchFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortIdx: 0,
      sortRev: false,
    };
  },
  computed: {
    displayItems() {
      const searchResult = this.searchItems(this.items);
      const sortedItems = this.sortItems(searchResult.map((r) => r.item));
      const paginatedItems = this.paginateItems(sortedItems);
      return paginatedItems.map((item) => {
        const searchResultItem = searchResult.find((r) => r.item === item);
        return {
          ...item,
          _searchScore: searchResultItem ? searchResultItem.score : 0,
        };
      });
    },
  },
  methods: {
    sort(idx) {
      this.sortRev = this.sortIdx == idx ? !this.sortRev : false;
      this.sortIdx = idx;
    },
    sortItems(items) {
      const field = this.fields[this.sortIdx];
      const fieldType = this.fieldTypes[field] || "text";

      let sortedItems = _.sortBy(items, (item) => {
        const value = item[field];
        if (fieldType === "numeric") {
          return Number(value);
        }
        return value;
      });

      return this.sortRev ? _.reverse(sortedItems) : sortedItems;
    },
    /*

        searchItems(items) {
            // clear timeout variable
            clearTimeout(this.timeout);

            const search = this.search.replace(/\s+/g, ' ').trim()

            this.timeout = setTimeout(function () {
                // enter this block of code after 1 second
                // handle stuff, call search API etc.

                if( !search || search.length<=3 ) {
                    return this.items;
                } else {
                    const tokens = search.split(' ');
                    return _.filter(items, item => {
                        const matchedFields = _.filter(this.fields, field => {
                            const matchedTokens = _.filter(tokens, token => {
                                const pattern = new RegExp(token, 'i')
                                return String(item[field]).search(pattern) > -1
                            })
                            return matchedTokens.length == tokens.length
                        })
                        return matchedFields.length
                    })
                }
            }, 1000);
        },

        */
    /*
    searchItems(items) {
      const search = this.search.replace(/\s+/g, " ").trim();

      if (!search || search.length <= 2) {
        return this.items;
      } else {
        const tokens = search.split(" ");
        return _.filter(items, (item) => {
          const matchedFields = _.filter(this.fields, (field) => {
            const matchedTokens = _.filter(tokens, (token) => {
              const pattern = new RegExp(token, "i");
              return String(item[field]).search(pattern) > -1;
            });
            return matchedTokens.length == tokens.length;
          });
          return matchedFields.length;
        });
      }
    },
	*/
    searchItems(items) {
      const search = this.search.trim();

      if (!search) {
        return items.map((item) => ({ item, score: 0 }));
      }

      const searchTerms = this.parseSearchTerms(search);
      const allSearchableFields = [
        ...this.fields,
        ...this.additionalSearchFields,
      ];

      return items
        .map((item) => {
          let score = 0;
          const matches = searchTerms.every((term) => {
            let termMatched = false;

            allSearchableFields.forEach((field) => {
              const fieldValue = String(item[field]);
              const fieldType = this.fieldTypes[field] || "text";

              let match = false;

              if (term.isPhrase) {
                // For quoted searches, match exact phrase in all fields
                match = this.matchText(fieldValue, term.value, true);
                if (match) {
                  score +=
                    fieldType === "numeric"
                      ? field === "client_branch"
                        ? 2
                        : 1
                      : 0.5;
                }
              } else if (!isNaN(Number(term.value))) {
                // For unquoted numeric searches, match across all fields
                match = this.matchText(fieldValue, term.value, false);
                if (match) score += 0.1;
              } else {
                // For unquoted non-numeric searches, match across all fields
                match = this.matchText(fieldValue, term.value, false);
                if (match) score += 0.1;
              }

              if (match) termMatched = true;
            });

            return termMatched;
          });

          return { item, score: matches ? score : -1 };
        })
        .filter(({ score }) => score > 0)
        .sort((a, b) => b.score - a.score);
    },

    matchText(fieldValue, searchValue, isExact) {
      const normalizedField = fieldValue.toLowerCase();
      const normalizedSearch = searchValue.toLowerCase();

      if (isExact) {
        return normalizedField === normalizedSearch;
      } else {
        return normalizedSearch
          .split(" ")
          .every((word) => normalizedField.includes(word));
      }
    },

    matchNumeric(fieldValue, searchValue) {
      const fieldNum = Number(fieldValue);
      const searchNum = Number(searchValue);

      return fieldNum === searchNum;
    },

    parseSearchTerms(search) {
      const terms = [];
      let currentTerm = "";
      let isInQuotes = false;

      for (let i = 0; i < search.length; i++) {
        if (search[i] === '"') {
          isInQuotes = !isInQuotes;
          if (!isInQuotes && currentTerm) {
            terms.push({ value: currentTerm, isPhrase: true });
            currentTerm = "";
          }
        } else if (search[i] === " " && !isInQuotes) {
          if (currentTerm) {
            terms.push({ value: currentTerm, isPhrase: false });
            currentTerm = "";
          }
        } else {
          currentTerm += search[i];
        }
      }

      if (currentTerm) {
        terms.push({ value: currentTerm, isPhrase: isInQuotes });
      }

      return terms;
    },

    paginateItems(items) {
      this.$emit("update:pages", _.ceil(items.length / this.itemsPerPage));
      const skip = (this.page - 1) * this.itemsPerPage;
      return _.slice(items, skip, skip + this.itemsPerPage);
    },
  },
  watch: {
    search() {
      this.$emit("update:page", 1);
    },
  },
  created() {
    if (this.defaultSort) {
      this.sortIdx = this.defaultSort.sortIdx;
      this.sortRev = this.defaultSort.sortRev;
    }
  },
};
</script>
