var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.app_promotion)?_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"col-xs-12 col-md-6 col-lg-6",staticStyle:{"z-index":"1"}},[_c('div',{staticClass:"vercenter"},[_c('h2',{staticClass:"hs1"},[_vm._v(" "+_vm._s(_vm.app_promotion.translates[_vm.app_promotion.default_lang].name)+" ")]),_c('p'),_c('p',{staticClass:"small matb10",attrs:{"title":_vm.app_promotion.active_to}},[_vm._v(" "+_vm._s(_vm.$t("default.endOfpromotion"))+" "+_vm._s(_vm._f("asCounter")(_vm.timeToEnd))+". ")]),_c('div',{staticClass:"buttons-wrap"},[(_vm.app_promotion.translates[_vm.app_promotion.default_lang].statute)?_c('a',{staticClass:"abtn--c5 mat15",attrs:{"href":"#"},on:{"click":function($event){return _vm.OPEN_POPUP_STATUTE(
              _vm.app_promotion.translates[_vm.app_promotion.default_lang].statute
            )}}},[_vm._v(_vm._s(_vm.$t("default.seePromotionRegulations")))]):_vm._e(),(
            _vm.app_promotion.translates[_vm.app_promotion.default_lang].leaflet_file
          )?_c('a',{staticClass:"abtn--c4 mat15",attrs:{"target":"_blank","href":_vm.app_promotion.translates[_vm.app_promotion.default_lang].leaflet_file}},[_vm._v(_vm._s(_vm.$t("default.leaflet")))]):_vm._e(),(
            !_vm.isTargetError && _vm.targets.clients && _vm.targets.clients.length > 0
          )?_c('a',{staticClass:"abtn--c4 mat15",attrs:{"target":"_blank","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadTargets.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("default.downloadTargets")))]):_vm._e()])])]),_c('div',{staticClass:"col-xs-12 col-md-6 col-lg-5 col-lg-push-1 pat10"},[_c('div',{staticClass:"imgh3xx"},[(_vm.app_promotion.image)?_c('img',{staticClass:"imgr",attrs:{"src":_vm.app_promotion.image}}):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }