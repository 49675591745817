<template>
  <main class="main1">
    <div class="container">
      <div class="row tabs-wrap">
        <div class="col-xs-12 col-md-9 col-lg-8 pab10">
          <!-- <h3>{{ $t("default.APpromotions") }}</h3> -->
          <div class="tabs">
            <button
              @click="setActiveTab('current')"
              :class="{ active: activeTab === 'current' }"
            >
              {{ $t("default.APpromotions") }}
              <span
                v-if="
                  searchTerm &&
                  (promotionsSorted.length > 0 ||
                    appPromotionsSorted.length > 0)
                "
                >({{
                  promotionsSorted.length + appPromotionsSorted.length
                }})</span
              >
            </button>
            <button
              @click="setActiveTab('workshop')"
              :class="{ active: activeTab === 'workshop' }"
            >
              {{ $t("default.APpromotionsWorkshop") }}
              <span v-if="searchTerm && appPromotionsWorkshopSorted.length > 0"
                >({{ appPromotionsWorkshopSorted.length }})</span
              >
            </button>
            <button
              @click="setActiveTab('maxserwis')"
              :class="{ active: activeTab === 'maxserwis' }"
            >
              {{ $t("default.APpromotionsMaxserwis") }}
              <span v-if="searchTerm && appPromotionsMaxserwisSorted.length > 0"
                >({{ appPromotionsMaxserwisSorted.length }})</span
              >
            </button>
            <button
              @click="setActiveTab('archive')"
              :class="{ active: activeTab === 'archive' }"
            >
              {{ $t("default.APpromotionsArchive") }}
              <span v-if="searchTerm && appPromotionsArchiveSorted.length > 0"
                >({{ appPromotionsArchiveSorted.length }})</span
              >
            </button>
          </div>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-4">
          <div class="search-wrap">
            <div class="search-container">
              <input
                v-model="searchTerm"
                type="text"
                class="form-control"
                :placeholder="$t('default.searchPromotions')"
              />
              <button
                v-if="searchTerm"
                @click="clearSearch"
                class="clear-search"
              >
                &times;
              </button>
            </div>
            <button @click="toggleViewMode" class="btn btn-secondary btn-small">
              {{
                viewMode === "grid"
                  ? $t("default.tableView")
                  : $t("default.gridView")
              }}
            </button>
          </div>
        </div>
      </div>

      <p v-if="loading" class="loading">{{ $t("default.loading") }}</p>
      <div
        v-else
        class="prminiarea prar-new prsize1"
        :class="[`promo-${activeTab}`]"
      >
        <div class="col-xs-12 pab30">
          <h3>
            {{ currentPromotionsTitle }}
          </h3>
        </div>
        <div v-if="viewMode === 'grid'" class="row">
          <!-- Existing grid view code -->
          <PromotionNewItem
            v-for="promotion in currentPromotions"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-else>
          <!-- New table view -->
          <table class="table table-filia">
            <thead>
              <tr>
                <th>{{ $t("tables.promotion") }}</th>
                <th>{{ $t("tables.activeFrom") }}</th>
                <th>{{ $t("tables.activeTo") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="promotion in currentPromotions"
                :key="promotion.id"
                @click="goToPromotion(promotion)"
              >
                <td>{{ promotion.translates[promotion.default_lang].name }}</td>
                <td>{{ promotion.active_from }}</td>
                <td>{{ promotion.active_to }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="searchTerm && currentPromotions.length === 0"
          class="mat40 patb50 text-center"
        >
          {{ $t("default.noSearchResults") }}
        </div>
        <!-- 
        <div v-if="noConnect" class="mat40 patb50 text-center">
          {{ $t("default.noData") }}
        </div>
        <div v-if="activeTab === 'current'" class="row">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotions") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
          <PromotionItem
            v-for="promotion in promotionsSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="activeTab === 'workshop'" class="row promo-workshop">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotionsWorkshop") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsWorkshopSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="activeTab === 'maxserwis'" class="row promo-maxserwis">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotionsMaxserwis") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsMaxserwisSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="activeTab === 'archive'" class="row promo-archive">
          <div class="col-xs-12 pab30">
            <h3>
              {{ $t("default.APpromotionsArchive") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsArchiveSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="noConnect" class="mat40 patb50 text-center">
          {{ $t("default.noData") }}
        </div>
		-->
      </div>
    </div>
  </main>
</template>

<script>
// import PromotionItem from "@/components/PromotionItem";
import PromotionNewItem from "@/components/PromotionNewItem";
import { mapGetters, mapActions } from "vuex";
import { FETCH_PROMOTIONS } from "../store/actions.type";
import localforage from "localforage";

export default {
  components: {
    // PromotionItem,
    PromotionNewItem,
  },
  data() {
    return {
      promotions: [],
      loading: false,
      noConnect: false,
      activeTab: "current",
      searchTerm: localStorage.getItem("apfilia-searchTerm") || "",
      viewMode: "grid",
    };
  },
  computed: {
    ...mapGetters([
      "isPopupStatuteOpen",
      "init",
      "myInfo",
      "app_promotions",
      "app_promotions_archive",
    ]),
    promotionsSorted() {
      if (this.promotions) {
        let data = JSON.parse(JSON.stringify(this.promotions));
        data = this.filterPromotions(data);
        return data.sort(
          (a, b) => Date.parse(a.ended_at) - Date.parse(b.ended_at)
        );
      }
      return [];
    },
    appPromotionsSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        data = data.filter((item) => {
          const groups = item.available_groups;
          return !(
            (groups.length === 2 &&
              groups.includes("MAXSERWIS") &&
              groups.includes("AP PRACOWNICY")) ||
            (groups.length === 1 && groups.includes("MAXSERWIS"))
          );
        });
        data = this.filterPromotions(data);
        return data.sort(
          (a, b) => Date.parse(a.active_to) - Date.parse(b.active_to)
        );
      }
      return [];
    },
    appPromotionsArchiveSorted() {
      if (this.app_promotions_archive) {
        let data = JSON.parse(JSON.stringify(this.app_promotions_archive));
        data = this.filterPromotions(data);
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
    appPromotionsWorkshopSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        data = data.filter((item) => item.for_workshops);
        data = this.filterPromotions(data);
        return data.sort(
          (a, b) => Date.parse(a.active_to) - Date.parse(b.active_to)
        );
      }
      return [];
    },
    appPromotionsMaxserwisSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        data = data.filter((item) => {
          const groups = item.available_groups;
          return (
            (groups.length === 2 &&
              groups.includes("MAXSERWIS") &&
              groups.includes("AP PRACOWNICY")) ||
            (groups.length === 1 && groups.includes("MAXSERWIS"))
          );
        });
        data = this.filterPromotions(data);
        return data.sort(
          (a, b) => Date.parse(a.active_to) - Date.parse(b.active_to)
        );
      }
      return [];
    },
    currentPromotions() {
      switch (this.activeTab) {
        case "current":
          return [...this.appPromotionsSorted, ...this.promotionsSorted];
        case "workshop":
          return this.appPromotionsWorkshopSorted;
        case "maxserwis":
          return this.appPromotionsMaxserwisSorted;
        case "archive":
          return this.appPromotionsArchiveSorted;
        default:
          return [];
      }
    },
    currentPromotionsTitle() {
      switch (this.activeTab) {
        case "current":
          return this.$t("default.APpromotions");
        case "workshop":
          return this.$t("default.APpromotionsWorkshop");
        case "maxserwis":
          return this.$t("default.APpromotionsMaxserwis");
        case "archive":
          return this.$t("default.APpromotionsArchive");
        default:
          return this.$t("default.APpromotions");
      }
    },
  },
  async created() {
    try {
      const value = await localforage.getItem("vuex");
      if (!value) {
        this.loading = true;
      }
    } catch (err) {
      // This code runs if there were any errors
      // eslint-disable-next-line no-console
      console.log(err);
    }
    this.load();
    this.loadActiveTab();
    this.setActiveTabFromHash(); // This will override the loaded tab if there's a hash in the URL
    window.addEventListener("hashchange", this.setActiveTabFromHash);
  },
  destroyed() {
    window.removeEventListener("hashchange", this.setActiveTabFromHash);
  },
  methods: {
    ...mapActions([
      FETCH_PROMOTIONS,
      "FETCH_NEW_PROMOTIONS",
      "FETCH_NEW_PROMOTIONS_ARCHIVE",
    ]),
    async load() {
      if (!this.init || !this.myInfo) {
        return;
      }

      try {
        this.promotions = await this.FETCH_PROMOTIONS();
        this.promotions = this.promotions.map((promotion) => {
          return {
            ...promotion,
            old: true,
            image: promotion.images[0].filename,
            for_workshops: false,
            available_groups: [],
            default_lang: 1,
            translates: {
              1: {
                name: promotion.name,
                description: promotion.contents.description_long,
                statute: promotion.contents.statute,
                leaflet_file: null,
                duration_info: null,
              },
            },
            active_from: promotion.started_at,
            active_to: promotion.ended_at,
          };
        });
        await this.FETCH_NEW_PROMOTIONS();
        await this.FETCH_NEW_PROMOTIONS_ARCHIVE();
      } catch (error) {
        this.noConnect = error;
      } finally {
        this.loading = false;
      }
    },
    saveActiveTab() {
      localStorage.setItem("apfilia-activeTab", this.activeTab);
      localStorage.setItem("apfilia-viewMode", this.viewMode);
    },
    loadActiveTab() {
      const savedTab = localStorage.getItem("apfilia-activeTab");
      const savedMode = localStorage.getItem("apfilia-viewMode");
      if (
        savedTab &&
        ["current", "workshop", "maxserwis", "archive"].includes(savedTab)
      ) {
        this.activeTab = savedTab;
      } else {
        this.activeTab = "current"; // Default tab
      }
      if (savedMode && (savedMode === "grid" || savedMode === "table")) {
        this.viewMode = savedMode;
      } else {
        this.viewMode = "grid"; // Default view mode
      }
    },
    setActiveTabFromHash() {
      const hash = window.location.hash.slice(1);
      const [tab, mode] = hash.split("-");
      if (["current", "workshop", "maxserwis", "archive"].includes(tab)) {
        this.activeTab = tab;
        if (mode === "table" || mode === "grid") {
          this.viewMode = mode;
        }
        this.saveActiveTab();
      } else {
        this.loadActiveTab();
        this.updateHash();
      }
    },
    updateHash() {
      window.location.hash = `${this.activeTab}-${this.viewMode}`;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.saveActiveTab();
      this.updateHash();
    },
    setViewMode(mode) {
      this.viewMode = mode;
      this.saveActiveTab();
      this.updateHash();
    },
    filterPromotions(promotions) {
      if (!this.searchTerm) return promotions;

      const searchLower = this.searchTerm.toLowerCase();
      return promotions.filter((promotion) => {
        const nameMatch = promotion.translates
          ? promotion.translates[promotion.default_lang]?.name
              ?.toLowerCase()
              .includes(searchLower)
          : promotion.name?.toLowerCase().includes(searchLower);

        const dateMatch = promotion.translates
          ? promotion.translates[promotion.default_lang]?.duration_info
              ?.toLowerCase()
              .includes(searchLower)
          : false;
        const groupMatch = promotion.available_groups?.some((group) =>
          group.toLowerCase().includes(searchLower)
        );
        return nameMatch || dateMatch || groupMatch;
      });
    },
    clearSearch() {
      this.searchTerm = "";
      localStorage.removeItem("apfilia-searchTerm");
    },
    toggleViewMode() {
      const newMode = this.viewMode === "grid" ? "table" : "grid";
      this.setViewMode(newMode);
    },
    saveSearchTerm() {
      localStorage.setItem("apfilia-searchTerm", this.searchTerm);
    },
    goToPromotion(promotion) {
      let path = promotion.old ? "/promotions" : "/promotions/app";
      this.$router.push({ path: path + "/" + promotion.id });
    },
    clearAllSavedState() {
      localStorage.removeItem("apfilia-activeTab");
      localStorage.removeItem("apfilia-viewMode");
      localStorage.removeItem("apfilia-searchTerm");
      // Reset component state
      this.activeTab = "current";
      this.viewMode = "grid";
      this.searchTerm = "";
    },
  },
  watch: {
    init() {
      this.load();
    },
    myInfo() {
      this.load();
    },
    activeTab() {
      this.updateHash();
    },
    searchTerm() {
      this.saveSearchTerm();
    },
  },
};
</script>

<style scoped>
.table-filia {
  background: #fff;
  border-radius: 8px;
}
.table-filia tbody tr:hover {
  background: #f2f2f2;
}
.search-wrap {
  display: flex;
  gap: 4px;
  /* flex-wrap: wrap; */
}
.search-container {
  position: relative;
  flex-grow: 1;
}
.clear-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #999;
}

.clear-search:hover {
  color: #333;
}
.tabs {
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 4px 8px;
  border: none;
  background-color: #fff;
  color: #0c315d;
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s;
  height: auto;
  font-size: 12px;
  min-height: 36px;
}

.tabs button.active {
  background-color: #0c315d;
  color: white;
  font-family: "Prompt", sans-serif;
  font-weight: 600;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}
.btn-small {
  height: 36px;
  padding: 4px 8px;
  font-size: 12px;
  color: #000;
}
@media (max-width: 667px) {
  .tabs-wrap {
    background-image: radial-gradient(
      circle at 50% 53%,
      rgba(58, 88, 123, 0.8),
      #3a587b
    );
  }
  .tabs {
    flex-wrap: wrap;
  }

  .tabs button {
    width: 100%;
  }
}
</style>
